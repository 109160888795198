// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactos-mdx": () => import("./../../../src/pages/contactos.mdx" /* webpackChunkName: "component---src-pages-contactos-mdx" */),
  "component---src-pages-fotos-mdx": () => import("./../../../src/pages/fotos.mdx" /* webpackChunkName: "component---src-pages-fotos-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-slug-js": () => import("./../../../src/pages/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-mdx-slug-js" */),
  "component---src-pages-privacidade-mdx": () => import("./../../../src/pages/privacidade.mdx" /* webpackChunkName: "component---src-pages-privacidade-mdx" */),
  "component---src-pages-servicos-mdx": () => import("./../../../src/pages/servicos.mdx" /* webpackChunkName: "component---src-pages-servicos-mdx" */),
  "component---src-pages-termos-mdx": () => import("./../../../src/pages/termos.mdx" /* webpackChunkName: "component---src-pages-termos-mdx" */)
}

